.start {
	position: relative;
	background-size: cover;
	background-position: center center;
	background-image: url(../../../public/assets/img/itaxi-tesla-bg.jpg);
	padding: 40px 20px 20px;
	overflow: hidden;
	margin-top: -60px;

	@include XGA {
		padding: 80px 20px 20px;
	}

	&.b2c {
		background-image: url(../../../public/assets/img/itaxi-lex-bg.jpg);
		background-position: bottom center;
	}

	&.b2b {
		.btn {
			background-color: #000;
			color: #fff;
			border-color: #000;

			&:hover {
				background-color: #fff;
				color: #000;
			}

			&.small {
				width: 100%;
				text-transform: initial;
			}
		}
	}

	.terms {
		font-size: 120%;
		font-weight: 700;

		a {
			color: $yellow;
			text-decoration: none;
		}
	}

	.social-buttons {
		button {
			display: block;
			margin: 10px auto;
		}

		.fb-btn {
			background-color: #4c69ba;
			border: #4c69ba solid 2px;
			color: #fff;
			text-align: center;
			font-weight: 700;
			width: 140px;
			padding: 10px 0;
			cursor: pointer;
		}
	}

	.checkboxes {
		position: relative;
		margin: 40px 0;
		font-size: 12px;

		input[type="checkbox"] {
		    position: absolute;
		    left: -30px;
		    display: none;
		}

		span {
	    	color: $green;

	    	&.more {
	    		position: relative;
	    		display: inline-block;
	    		font-weight: 600;
	    		padding-left: 35px;
	    		top: -8px;
	    		cursor: pointer;
	    	}
	    }

	    .parsley-errors-list {
	    	padding-left: 35px;
	    }

		label {
		    padding-bottom: 0;
		    display: inline-block;
		    position: relative;
		    user-select: none;
		    cursor: pointer;
		    margin: 10px auto;
		    color: #000;
		    border-radius: 5px;
		    padding-left: 35px;

		    a {
		    	font-weight: 700;
		    	color: #000;

		    	&:hover {
		    		text-decoration: underline;
		    	}
		    }

		    &:before {
		    	content: "";
		    	position: absolute;
		    	left: 2px;
		    	top: -2px;
				box-sizing: border-box;
				display: inline-block;
				width: 20px;
				height: 20px;
				background-color: #eff1f6;
				border: #000 solid 2px;
				transition: background-color .2s $CubicEaseOut;
		    }

		    &:after {
		    	content: "";
		    	position: absolute;
		    	left: 4px;
		    	top: 0px;
				box-sizing: border-box;
				display: inline-block;
				width: 16px;
				height: 16px;
				background-color: $yellow;
				transition: background-color .2s $CubicEaseOut;
				opacity: 0;
				transition: opacity .2s $CubicEaseInOut;
		    }
		  }

		  input[type="checkbox"]:checked + label {

		    &:after {
		      opacity: 1;
		    }
		  }

		.checkbox-error {
			position: absolute;
			top: 100%;
			left: 40px;
			color: #f22;
		}
	}

	.parsley-errors-list {
		list-style: none;
		padding: 0;
		margin: 0;
		margin-top: 5px;
		font-size: 12px;
		color: #f22;
	}

	.close {
		position: absolute;
		display: inline-block;
		text-align: right;
		right: 20px;
		top: 10px;
		width: 24px;
		height: 24px;

		@include XGA {
			top: 20px;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 12px;
			width: 100%;
			height: 3px;
			background-color: #000;
			transform: rotate(45deg);
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 12px;
			width: 100%;
			height: 3px;
			background-color: #000;
			transform: rotate(-45deg);
		}
	}

	.have-account {
		position: relative;
		text-align: right;
		top: 0;
		right: 20px;
		margin-bottom: 30px;

		@include XGA {
			margin-top: -20px;
		}

		a {
			color: $yellow;
		}
	}

	.switch-type {
		margin: 40px 0;

		a {
			color: #000;
			text-decoration: none;
		}

		.icon {
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background-color: #fff;
			margin: 20px auto;
		}

		.switch-types {
			display: flex;
			flex-flow: row;
			flex-wrap: nowrap;
			justify-content: center;

			.switch {
				position: relative;
				margin: 0 15px;
				width: 40px;
				height: 20px;
				border: #000 solid 2px;
				border-radius: 10px;
				background-color: #dfdfdf;

				&:before {
					content: "";
					position: absolute;
					left: -2px;
					top: -2px;
					width: 16px;
					height: 16px;
					border-radius: 50%;
					background-color: $yellow;
					border: #000 solid 2px;
				}

				&.b2b {
					&:before {
						left: auto;
						right: -2px;
					}
				}
			}
		}
	}

	@include XGA {
		padding: 100px 40px 40px;
		background-position: left center;
	}

	.start-box {
		width: 100%;
		max-width: 800px;
		margin: 0 auto;
		background-color: rgba(255, 255, 255, 0.85);
		text-align: center;
		border-radius: 12px;
		padding: 20px;
		box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 25px;
		opacity: 0;
		transform: translateX(140px);
		transition: opacity .4s $QuadEaseInOut, transform .4s $QuadEaseInOut;

		&.show {
			opacity: 1;
			transform: translateX(0);
		}

		@include XGA {
			padding: 40px;
		}

		h3 {
			font-weight: 900;
			font-size: 24px;
		}

		.inputs {
			h3 {
				text-transform: uppercase;
				margin-top: 10px;
			}
		}

		.input {
			text-align: left;
			width: 100%;
			max-width: 360px;
			margin: 20px auto;
		}

		input {
			width: 100%;
			background-color: transparent;
			border: rgba(0, 0, 0, 0.3) solid 1px;
			border-radius: 5px;
			margin-top: 5px;
			font-size: 18px;
			padding: 10px 20px;
			outline: none;

			&.input-error {
				border-color: #b53030 !important;
			}

			&:focus {
				border-color: #000;
			}
		}

		input.parsley-error {
			border: #ff4444 solid 1px;
		}

		.checkbox.parsley-error > label {
			color: #ff4444;
		}

		.error {
			color: #b53030;
			font-size: 12px;
			margin: 5px;
		}

		label {
			display: block;
			font-size: 14px;
			font-weight: 400;

			span {
				color: #f44;
			}
		}

		.btn-holder {
			margin-top: 35px;
		}

		.remind {
			display: inline-block;
			color: $yellow;
			font-size: 14px;
			font-weight: 900;
			margin: 30px 0;
		}

		.separator {
			margin: 40px 0;
			width: 100%;
			height: 1px;
			background-color: rgba(0, 0, 0, 0.15);
		}
	}

	.benefits {
		text-align: left;

		@include XGA {
			padding: 0 50px;
		}

		ul {
			margin-left: 0;
			padding-left: 20px;

			li {
				font-size: 18px;
				font-weight: 900;
				line-height: 1.7;
			}
		}
	}

	.access {
		font-size: 13px;
		color: rgba(0, 0, 0, 0.5);
		margin-top: 50px;

		@include HD {
			font-size: 15px;
		}

		a {
			color: rgba(0, 0, 0, 0.5);
			text-decoration: none;
			font-weight: 900;
		}
	}

	.sep {
		margin: 40px 0 40px;
		height: 2px;
		background-color: rgba(0, 0, 0, 0.35);
	}
}