.btn {
	display: inline-block;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 900;
	min-width: 250px;
	border-radius: 30px;
	border: $yellow solid 2px;
	cursor: pointer;
	user-select: none;
	background-color: $yellow;
	outline: none;
	transition: background-color .2s $CubicEaseInOut, color .2s $CubicEaseInOut;
	text-decoration: none;
	color: #000;
	text-align: center;
	box-shadow: rgba(0, 0, 0, 0.15) 5px 5px 30px;
	padding: 0 10px;

	&:hover {
		background-color: #fff;
	}

	&:active {
		transform: scale(0.96);
	}

	span {
		line-height: 58px;
	}

	&.small {
		font-size: 14px;
		min-width: 180px;

		span {
			line-height: 40px;
		}
	}

	&.transparent {
		background-color: transparent;

		&:hover {
			background-color: $yellow;
		}
	}

	&.violet {
		border: none;
		background: linear-gradient(to bottom, #a410b7 0%, #7b0dc0 100%);

		span {
			color: #fff;
			line-height: 62px;
		}

		&.small {
			span {
				line-height: 44px;
			}
		}

		&.vsmall {
			min-width: 130px;

			@include HD {
				min-width: 150px;
			}

			span {
				line-height: 30px;
				font-size: 14px;

				@include HD {
					line-height: 40px;
				}
			}
		}
	}

	&.green {
		border-color: $green;
		background-color: $green;

		&:hover {
			background-color: #fff;
		}

		span {
			color: #000;
			line-height: 58px;
		}

		&.small {
			span {
				line-height: 40px;
			}
		}
	}
}