footer {
	background-color: #fff;
	padding: 30px 0;

	@include XGA {
		padding: 50px 0;
	}

	.container {
		position: relative;
		width: 90%;
		max-width: 1200px;
		margin: 0 auto;
		text-align: center;
	}
}