.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 0.2s $QuadEaseOut;
  transition-delay: 0.2s;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 0.2s $QuadEaseOut;
}

.user-enter {
  opacity: 0;
}

.user-enter-active {
  opacity: 1;
  transition: opacity 0.15s $QuadEaseOut;
  transition-delay: 0.15s;
}

.user-exit {
  opacity: 1;
}

.user-exit-active {
  opacity: 0;
  transition: opacity 0.15s $QuadEaseOut;
}

.notification-enter {
  opacity: 0;
}

.notification-enter-active {
  opacity: 1;
  transition: all 0.15s $QuadEaseOut;
}

.notification-exit {
  opacity: 1;
}

.notification-exit-active {
  opacity: 0;
  transition: all 0.15s $QuadEaseOut;
}

@keyframes spin{
  0% {
    transform: rotate(0deg);  
  }

  100% {
    transform: rotate(360deg); 
  }
}

@keyframes scale {
  0% {
    transform: scale(1);  
  }

  50% {
    transform: scale(0.85); 
  }

  100% {
    transform: scale(1); 
  }
}

@keyframes scale2 {
  0% {
    transform: scale(1);  
  }

  50% {
    transform: scale(0.95); 
  }

  100% {
    transform: scale(1); 
  }
}