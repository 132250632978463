// ------------------------------------------------
// Site Name: 
// Author: 
// ------------------------------------------------

* {
	box-sizing: border-box;
}

body, html {
  margin: 0;
}

body {
  font-family: $main;
  background-color: #f0f0f0;
}

main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
}

.app-inner {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  background-color: #f0f0f0;
}

.page-holder {
  position: relative;
  width: 100%;
  margin-top: 120px;
  background-color: #f0f0f0;

  @include XGA {
    margin-top: 60px;
  }
}

input[type="search"] {
    -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.preloader-mini {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 44px;
  min-width: 44px;
  max-width: 44px;
  min-height: 44px;
  max-height: 44px;
  padding: 10px;
  
  &:after {
    content: "";
    position: absolute;
    left: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    border: #000 solid 3px;
    border-left-color: $yellow;
    border-radius: 50%;
    animation: spin 1s $CubicEaseInOut infinite;
  }
}

main {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

section {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #f0f0f0;

  @include HD {
    padding: 40px;
  }
}

.box {
  position: relative;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 40px 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0 15px 40px;

  @include XGA {
    margin-bottom: 40px;
  }
}

.error-page {
  min-height: calc( 100vh - 120px );

  @include XGA {
    min-height: calc( 100vh - 60px );
  }

  .container {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    h1 {
      font-size: 60px;
      color: #000;
      margin-top: 0;
    }

    a {
      color: $yellow;
    }
  }
}

.error-box {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-radius: 12px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 25px;
  opacity: 1;
  transform: translateX(0);
  transition: opacity .4s $QuadEaseInOut, transform .4s $QuadEaseInOut;

  @include XGA {
    padding: 40px;
  }
}

.select {
    label {
      font-weight: 900;
    }

    input[type="radio"] {
        position: absolute;
        left: -30px;
        display: none;
    }

    label {
        padding-bottom: 0;
        display: block;
        position: relative;
        user-select: none;
        cursor: pointer;
        border-radius: 5px;
        padding-left: 35px;
        margin: 20px 0;

        input {
          margin-bottom: 0;
          margin-left: 20px;
        }

        a {
          font-weight: 700;
          color: #000;

          &:hover {
            text-decoration: underline;
          }
        }

        &:before {
          content: "";
          position: absolute;
          left: 2px;
          top: 50%;
          transform: translateY(-50%);
        box-sizing: border-box;
        display: inline-block;
        width: 18px;
        height: 18px;
        border: rgba(0, 0, 0, 0.15) solid 1px;
        border-radius: 50%;
        }

        &:after {
          content: "";
          position: absolute;
          left: 6px;
          top: 50%;
          transform: translateY(-50%);
        box-sizing: border-box;
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: $violet;
        border-radius: 50%;
        opacity: 0;
        transition: opacity .2s $CubicEaseInOut;
        }
      }

      input[type="radio"]:checked + label {

        &:after {
          opacity: 1;
        }
      }
  }

.check-box {
    label {
      font-weight: 900;
    }

    input[type="checkbox"], input[type="radio"] {
        position: absolute;
        left: -30px;
        display: none;
    }

    label {
        padding-bottom: 0;
        display: inline-block;
        position: relative;
        user-select: none;
        padding-left: 35px;
        margin: 24px 0;

        input {
          margin-bottom: 0;
          margin-left: 20px;
        }

        a {
          font-weight: 700;
          color: #000;

          &:hover {
            text-decoration: underline;
          }
        }

        &:before {
          content: "";
          position: absolute;
          left: 2px;
          top: 50%;
          transform: translateY(-50%);
          box-sizing: border-box;
          display: inline-block;
          width: 24px;
          height: 24px;
          border: $violet solid 2px;
        }

        &:after {
          content: "";
          position: absolute;
          left: 8px;
          top: 45%;
          width: 6px;
          height: 12px;
          border-right: #fff solid 2px;
          border-bottom: #fff solid 2px;
          transform: rotate(45deg) translate(-50%, -50%);
          opacity: 0;
        }
      }

      input[type="checkbox"]:checked + label, input[type="radio"]:checked + label {
        &:before {
          background-color: $violet;
        }
        
        &:after {
          opacity: 1;
        }
      }
  }

  .checkboxes {
    p {
			a {
				color: $yellow;
			}
		}
  }

.select-search {
  position: relative;
  max-width: 100%;
  width: 100%;

  .reset {
    position: absolute;
    right: 20px;
    top: 18px;

    img {
      height: 16px;
    }
  }

  ul {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    max-height: 160px;
    overflow-y: auto;
    list-style: none;
    margin: 0;
    padding: 20px 0;
    z-index: 10;
    background-color: #fff;
    border: rgba(0, 0, 0, 0.3) solid 1px;
    border-radius: 5px;
    margin-top: 5px;
    box-shadow: rgba(0, 0, 0, 0.15) 5px 5px 30px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity .3s $CubicEaseInOut, visibility .3s $CubicEaseInOut, transform .3s $CubicEaseInOut;

    &.open {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    li {
      padding: 10px 30px;
      cursor: pointer;

      &:hover {
        color: $violet;
      }

      &:nth-child(odd) {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    p {
      padding: 10px 30px;
      margin: 0;
    }
  }
}

::-webkit-scrollbar {
  @include HD {
	 width: 10px;
   height: 10px;
  }
}

::-webkit-scrollbar-button {
  @include HD {
    display: none;
  }
}

::-webkit-scrollbar-track {
  @include HD {
    position: relative;
    background-color: #cfcfcf;
    border-radius: 5px;
  }
}

::-webkit-scrollbar-track-piece {
  @include HD {
    background-color: #cfcfcf;
  }
}

::-webkit-scrollbar-thumb {
  @include HD {
    background-color: $yellow;
  }
}

::-webkit-scrollbar-corner {

}

::-webkit-resizer {

}

.hidden{
  display: none!important;
}