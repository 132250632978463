.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModalPortal > * {
    opacity: 0;
}

.overlay {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 40px;
    transition: opacity 250ms $CubicEaseInOut;
    background: transparent;
    overflow-y: auto;

    &:before {
        content: "";
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(5, 2, 7, 0.4)
    }

    &_after-open {
        opacity: 1;
    }
    &_before-close {
        opacity: 0;
    }

    .close {
        position: absolute;
        left: 50%;
        top: -25px;
        transform: translateX(-50%);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $violet;
        cursor: pointer;
        box-shadow: rgba(140, 20, 204, 0.15) 5px 5px 10px;

        @include vHD {
            left: auto;
            right: -50px;
        }

        &:before {
            content: "";
            position: absolute;
            left: 17px;
            top: 23px;
            width: 16px;
            height: 3px;
            background-color: #fff;
            transform: rotate(45deg);
        }

        &:after {
            content: "";
            position: absolute;
            left: 17px;
            top: 23px;
            width: 16px;
            height: 3px;
            background-color: #fff;
            transform: rotate(-45deg);
        }

        span {
            color: #fff;
            text-transform: uppercase;
            font-weight: 400;
            position: absolute;
            top: 6px;
            left: -54px;
            font-size: 14px;

            @include XGA {
                top: 12px;
            }
        }
    }
}

.modal {
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 520px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 60px;
    outline: none;
    padding: 40px 0;
    margin-bottom: 60px;

    @include vHD {
        padding: 50px 0;
        border-radius: 12px;
    }

    @include XGA {
        width: auto;
        max-width: 800px;
        min-width: 520px;
    }

    .password-modal {
        text-align: center;
        padding: 0 20px;

        input, textarea, select {
            width: 100%;
            background-color: transparent;
            border: rgba(0, 0, 0, 0.3) solid 1px;
            border-radius: 5px;
            margin-top: 5px;
            font-size: 18px;
            padding: 10px 20px;
            outline: none;
            margin-top: 10px;

            &.input-error {
                border-color: #b53030 !important;
            }

            &:focus {
                border-color: #000;
            }
        }

        .more {
            color: $yellow;
            font-weight: 900;
            text-decoration: underline;
            cursor: pointer;
            display: inline-block;
            margin-bottom: 10px;
            margin-top: 20px;
        }

        .additional-info-box {
            margin: 0 auto;
            margin-top: 30px;
            max-width: 380px;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.5);

            a {
                color: rgba(0, 0, 0, 0.5);
                font-weight: 900;
                text-decoration: none;
            }
        }

        &.voucher {
            .inputs {
                @include XGA {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;

                    .input {
                        max-width: 45%;
                    }
                }
            }
        }

        &.success-order {
            .inputs {
                p, li {
                    font-size: 18px;
                }
            }

            li {
                line-height: 1.5;
            }

            p, li {
                text-align: left;
            }

            p {
                margin-left: 40px;
            }

            ul {
                list-style: none;
            }

            strong {
                word-wrap: break-word;
            }
        }

        .select-search {
            display: inline-block;

            .reset {
                top: 23px;
            }
        }

        .car-class {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: center;
            border-top: #000 solid 1px;
            border-bottom: #000 solid 1px;
            padding: 20px 0;

            .cclass {
                margin: 0 20px;
                opacity: 0.6;
                cursor: pointer;
                transition: opacity .2s $CubicEaseInOut;

                &.active, &:hover {
                    opacity: 1;
                }

                p {
                    margin-bottom: 0;
                }
            }

            .icon {
                position: relative;
                width: 65px;
                height: 65px;
                background-color: #000;
                border-radius: 50%;

                &.economic {
                    background-color: $yellow;
                }

                img {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    max-width: 40px;
                    max-height: 35px;
                }
            }
        }

        .car-class-info {
            border-bottom: #000 solid 1px;
            margin-bottom: 30px;

            p {
                margin: 20px 0;
            }
        }

        .partners-list {
            overflow-y: auto;
            max-height: 200px;
        }

        .disclaimer {
            font-size: 13px;
            text-align: left;
            line-height: 1.5;
            margin: 30px;
        }

        &.print {
            p {
                text-align: left;
            }
        }

        .react-datepicker-wrapper {
            display: block;

            .react-datepicker__input-container {
                display: block;
            }
        }

        .card-info {
            margin-top: 30px;
            
            .buttons {
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
        }

        .profile-info {
            list-style: none;
            padding: 0;
            margin: 0;
            margin: 20px 20px;

            li {
                line-height: 1.4;
            }
        }

        .buttons {
            margin-top: 30px;

            &.vouchers {
                .btn {
                    width: 100%;
                    max-width: 320px;
                    display: block;
                    margin: 20px auto;
                }
            }
        }

        .inputs {
            h3 {
                font-weight: 900;
                font-size: 24px;
                margin-top: 0;
            }

            .flex {
                margin-top: 0;
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: space-between;

                &>div {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    margin-right: 15px;

                    input {
                        padding: 10px 10px;
                    }

                    label {
                        margin-top: 23px;
                        margin-right: 20px;
                    }
                }
            }

            .inputs {
                h3 {
                    text-transform: uppercase;
                    margin-top: 10px;
                }
            }

            .input {
                text-align: left;
                width: 100%;
                max-width: 360px;
                margin: 20px auto;
            }

            &.map {
                width: 100%;

                .leaflet-container {
                    height: 260px;
                    width: 100%;
                    z-index: 0;
                }
            }

            select {
                padding: 8px 20px;
            }

            textarea {
                min-height: 120px;
                min-width: 100%;
                max-width: 100%;
            }

            .error {
                color: #b53030;
                font-size: 12px;
                margin: 5px;
            }

            label, p {
                display: block;
                font-size: 14px;
                font-weight: 700;
            }

            .btn-holder {
                margin-top: 35px;
            }
        }

        // .accounts-search {
        //     input {
        //         width: 100%;
        //         max-width: 370px;
        //         padding: 15px;
        //         padding-left: 40px;
        //         margin: 5px 0;
        //         border: rgba(0, 0, 0, 0.1) solid 2px;
        //         border-radius: 12px;
        //         outline: none;
        //         background-size: 24px;
        //         background-repeat: no-repeat;
        //         background-position: 15px 13px;
        //         background-image: url("/assets/img/search.svg");
        //     }
        // }
    }

    .order-modal {
        text-align: center;

        h2 {
            text-transform: uppercase;
        }

        .btn {
            margin: 20px;
        }
    }

    .user-modal {
        .user-info {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            padding: 0 20px;
            padding-bottom: 40px;
            border-bottom: #efefef solid 2px;

            @include vHD {
                padding: 0 10px;
                margin: 0 40px;
                padding-bottom: 40px;
            }

            @include UXGA {
                padding-bottom: 60px;
            }

            .buttons {
                min-width: 100%;
                margin-top: 20px;
                padding: 0;
            }

            .avatar {
                margin-right: 20px;

                @include XGA {
                    margin-right: 40px;
                }

                .avatar-inner {
                    width: 65px;
                    height: 65px;
                    background-color: #000;
                    border-radius: 50%;
                    text-align: center;

                    @include XGA {
                        width: 70px;
                        height: 70px;
                    }

                    strong {
                        color: #fff;
                        line-height: 65px;
                        font-size: 26px;

                        @include XGA {
                            line-height: 70px;
                            font-size: 27px;
                        }
                    }
                }
            }

            .user-data {
                p {
                    font-size: 14px;
                    margin: 5px 0;

                    @include XGA {
                        margin: 10px 0;
                    }

                    strong {
                        font-size: 17px;
                        line-height: 30px;
                    }
                }
            }
        }

        .buttons {
            text-align: center;
            margin-top: 20px;
            padding: 0 20px;

            @include vHD {
                padding: 0 50px;
            }

            @include XGA {
                margin-top: 30px;
            }

            .btn {
                display: block;
                margin: 0 auto;
                margin-bottom: 15px;

                @include vHD {
                    display: inline-block;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .other-accounts {
            padding: 0 20px;
            text-align: center;

            @include vHD {
                padding: 0 50px;
            }

            h4 {
                font-size: 18px;
                margin: 0;
                margin-top: 40px;
                margin-bottom: 15px;

                @include vHD {
                    margin-top: 40px;
                }

                @include UXGA {
                    margin-top: 60px;
                }
            }

            .select-search {
                display: inline-block;
            }

            .reset {
                top: 22px;
            }

            input {
                width: 100%;
                max-width: 370px;
                padding: 15px;
                padding-left: 50px;
                margin: 5px 0;
                border: rgba(0, 0, 0, 0.1) solid 2px;
                border-radius: 12px;
                outline: none;
                background-size: 24px;
                background-repeat: no-repeat;
                background-position: 15px 13px;
                // background-image: url("/assets/img/search.svg");
            }

            .account-list {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    cursor: pointer;

                    .account-list-avatar {
                        margin-right: 20px;

                        span {
                            display: inline-block;
                            width: 45px;
                            height: 45px;
                            background-color: #000;
                            border-radius: 50%;
                            text-align: center;

                            strong {
                                color: #fff;
                                font-size: 18px;
                                line-height: 45px;
                            }
                        }
                    }

                    .account-list-name {
                        p {
                            font-size: 14px;
                            line-height: 1.8;
                            margin: 12px 0;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}