.notifications {
  position: fixed;
  top: 70px;
  right: 10px;
  z-index: 30000;

  @include XGA {
    top: 80px;
    right: 15px;
  }

  .notification {
    position: absolute;
    right: 0;
    top: 0;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 30px;
    font-size: 13px;
    padding: 15px 30px;
    padding-right: 50px;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.4s $QuadEaseInOut;

    &:before {
      content: "";
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 3px;
      height: 100%;
      z-index: 1;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: #fff;
    }

    &.red {
      &:before {
        background-color: #b53030;
      }
    }

    &.green {
      &:before {
        background-color: #0e9200;
      }
    }

    &.info {
      &:before {
        background-color: $yellow;
      }
    }

    .close {
      position: absolute;
      right: 0;
      top: 3px;
    }

    p {
      margin: 0;
    }
  }
}