header {
	position: relative;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 60px;
	background-color: $yellow;
	transform: translateY(-100%);
	transition: transform .5s $QuadEaseInOut;
	z-index: 100;
	padding: 0 20px;

	@include XGA {
		padding: 0 40px;
	}

	&.anim {
		transform: translateY(0);
	}

	.header-logo {
		display: flex;
		flex-flow: row;
		flex-wrap: nowrap;
		margin: 10px 0;

		.logo {
			height: 40px;
			margin-right: 15px;

			@include XGA {
				margin-right: 25px;
			}
		}

		p {
			margin: 0;
			font-weight: 900;
			font-size: 13px;
			line-height: 40px;

			@media screen and (min-width: 360px) {
				font-size: 15px;
			}

			@include vHD {
				font-size: 16px;
			}

			@include XGA {
				font-size: 18px;
			}
		}
	}

	.lang-switch {
		position: absolute;
		right: 0;
		top: 0;
		text-align: right;
		padding-right: 20px;
		padding-left: 20px;
		border-left: rgba(0, 0, 0, 0.15) solid 1px;
		height: 60px;
		transition: transform .3s $CubicEaseInOut .3s;

		@include XGA {
			padding-right: 40px;
			padding-left: 40px;
		}

		&.logged-in {
			transform: translateX(100%);
			transition-delay: 0s;

			@include vHD {
				transform: translateX(0);
			}
		}

		&.open {
			.lang-list {
				transform: translateY(0);
			}

			.current-lang {
				&:after {
					top: 28px;
					transform: rotate(135deg);
				}
			}
		}

		.current-lang {
			position: relative;
			line-height: 60px;
			text-transform: uppercase;
			font-weight: 900;
			padding-right: 20px;
			z-index: 10;
			background-color: $yellow;
			cursor: pointer;
			user-select: none;

			@include XGA {
				padding-right: 40px; 
			}

			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 21px;
				width: 10px;
				height: 10px;
				border-left: #000 solid 2px;
				border-bottom: #000 solid 2px;
				transform: rotate(-45deg);
				transition: all .3s $CubicEaseInOut;
			}
		}

		.lang-list {
			position: absolute;
			right: 0;
			top: 100%;
			list-style: none;
			padding: 0;
			margin: 0;
			background-color: $yellow;
			width: 100%;
			transform: translateY(-100%);
			transition: transform .5s $QuadEaseInOut;
			user-select: none;

			li {
				line-height: 40px;
				text-align: center;

				a {
					text-transform: uppercase;
					text-decoration: none;
					color: #000;
					font-weight: 900;
				}
			}
		}
	}

	.user-profile {
		position: absolute;
		right: 0;
		top: 0;
		text-align: right;
		padding-right: 20px;
		padding-left: 20px;
		border-left: rgba(0, 0, 0, 0.15) solid 1px;
		height: 60px;
		transform: translateY(-100%);
		transition: transform .3s $CubicEaseInOut 0s;
		display: flex;
		flex-flow: row;
		flex-wrap: nowrap;
		cursor: pointer;

		@include vHD {
			right: 80px;
		}

		@include XGA {
			padding-right: 40px;
			padding-left: 40px;
			border-left: none;
			right: 140px;
		}

		&.logged-in {
			transform: translateY(0);
			transition-delay: .3s;
		}

		.name, .company {
			position: relative;
			margin: 0;
			font-size: 16px;
			line-height: 40px;
			display: none;
			user-select: none;

			@include XGA {
				margin-top: 10px;
				display: inline-block;
				font-size: 18px;
				margin-right: 20px;
			}

			@include UXGA {
				margin-right: 30px;
			}
		}

		.profile-open {
			position: relative;
			padding-right: 20px;
			display: inline-block;

			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 21px;
				width: 10px;
				height: 10px;
				border-left: #000 solid 2px;
				border-bottom: #000 solid 2px;
				transform: rotate(-45deg);
				transition: all .3s $CubicEaseInOut;
			}

			.small {
				display: inline-block;
				font-size: 16px;
				width: 40px;
				height: 40px;
				background-color: #000;
				border-radius: 50%;
				margin-top: 10px;
				margin-right: 10px;
				text-align: center;

				@include XGA {
					font-size: 18px;
				}

				strong {
					line-height: 40px;
					color: #fff;
				}
			}
		}
	}
}